import dayjs from "dayjs";

export default {
  company: null,
  totalRetailValue: null,
  distanceUnits: null,
  pricePerDistanceUnit: null,
  systemOfMeasurement: null,
  currency: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
  fullInsurance: false,
  sellbackOption: false,
  sellbackPrice: null,
  sellbackExpirationDate: null,
  nextContractEvaluationDate: null,
  contractProductDeviceUsages: [],
  royalties: [],
};
