<template>
  <tr v-if="partnerRole">
    <th>
      <b-field :label="partnerRole.name" :label-for="partnerRole.id"></b-field>
    </th>
    <td>
      <CompanySelect
        :id="partnerRole.id"
        v-model="formRoyalty.company"
        :companies="companies"
      ></CompanySelect>
    </td>
    <td>
      <b-field>
        <b-select
          v-model="formRoyalty.user"
          required
          :disabled="companyUsers.length === 0"
          :placeholder="companyUsers.length === 0 ? $t('no_users_found') : ''"
        >
          <option
            v-for="u in companyUsers"
            :key="u.id"
            :value="u['@id']"
            :disabled="u.blocked"
          >
            {{ u.emailAddress }}
            {{ u.blocked ? `(${$t("blocked")})` : "" | lc }}
          </option>
        </b-select>
      </b-field>
    </td>
    <td>
      <CustomInput
        v-model="formRoyalty.commissionPercentage"
        :placeholder="'%'"
        :type="'number'"
        :horizontal="false"
        :invalid="commissionPercentageTotal !== 100"
      ></CustomInput>
    </td>
    <td>
      <small>
        {{ fee | money(currency.code) }}
      </small>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import CompanySelect from "../../../components/forms/CompanySelect";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CompanySelect, CustomInput },
  props: {
    form: {
      type: Object,
      required: true,
    },
    partnerRoleId: {
      type: String,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    commissionPercentageTotal: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      partnerRole: null,
    };
  },
  computed: {
    ...mapGetters(["findCurrencyByUri", "findPartnerRoleByUri"]),
    currency() {
      return this.findCurrencyByUri(this.form.currency);
    },
    formRoyalty() {
      return this.form.royalties.find(
        (r) => r.partnerRole === this.partnerRoleId
      );
    },
    companyUsers() {
      return this.users
        .filter((u) =>
          u.companies.some((c) => c.company === this.formRoyalty.company)
        )
        .map((u) => {
          return {
            ...u,
            blocked: u.companies.find(
              (c) => c.company === this.formRoyalty.company
            ).blocked,
          };
        });
    },
    fee() {
      const total =
        parseInt(this.form.distanceUnits || 0) *
        parseInt(this.form.pricePerDistanceUnit || 0) *
        parseInt(this.formRoyalty.commissionPercentage || 0);
      return total / 100;
    },
  },
  watch: {
    "formRoyalty.company"() {
      if (this.companyUsers[0]?.blocked) {
        this.formRoyalty.user = null;
        return;
      }
      this.formRoyalty.user = this.companyUsers[0]?.["@id"];
    },
  },
  mounted() {
    this.partnerRole = this.findPartnerRoleByUri(this.partnerRoleId);
  },
};
</script>
