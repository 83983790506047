<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <form v-if="!loading" class="mt-5" @submit.prevent="submit">
      <ContractFormGeneral v-if="!loading" :form="form" :companies="companies">
      </ContractFormGeneral>

      <ContractFormRoyalties
        v-if="!loading"
        :form="form"
        :companies="companies"
        :users="users"
        :commission-percentage-total="commissionPercentageTotal"
      ></ContractFormRoyalties>

      <ContractFormProducts
        v-if="!loading"
        :form="form"
        :products="products"
        class="mt-5"
      ></ContractFormProducts>

      <b-button
        type="is-primary"
        native-type="submit"
        icon-left="content-save"
        :loading="submitting"
        :disabled="!valid"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { sumBy } from "lodash-es";
import { mapActions, mapGetters, mapState } from "vuex";
import ContractFormGeneral from "./ContractFormGeneral";
import ContractFormProducts from "./ContractFormProducts";
import ContractFormRoyalties from "./ContractFormRoyalties";
import DefaultContract from "./DefaultContract";

export default {
  components: {
    ContractFormGeneral,
    ContractFormProducts,
    ContractFormRoyalties,
  },
  data() {
    return {
      loading: true,
      submitting: false,
      form: null,
      companies: null,
      products: null,
      users: null,
    };
  },
  computed: {
    ...mapGetters(["findPartnerRoleByUri", "partnerRolesInCustomOrder"]),
    ...mapState({
      currencies: (state) => state.global.currencies,
      systemOfMeasurements: (state) => state.global.systemOfMeasurements,
    }),
    commissionPercentageTotal() {
      return sumBy(
        Object.values(this.form.royalties),
        (r) => Math.round(parseInt(r.commissionPercentage) * 100) / 100
      );
    },
    valid() {
      return this.commissionPercentageTotal === 100;
    },
  },
  async mounted() {
    this.form = { ...DefaultContract };
    this.form.systemOfMeasurement = this.systemOfMeasurements[0]["@id"];
    this.form.currency = this.currencies[0]["@id"];

    this.companies = await this.getAllCompanies({ blocked: false });
    this.users = await this.getAllUsers();
    const reductId = this.companies.find((c) => c.name === "Reduct N.V.")?.[
      "@id"
    ];
    const reductUsers = this.users.filter((u) =>
      u.companies.some((c) => c.company === reductId)
    );
    const firstReductUserId = reductUsers[0]?.["@id"];
    this.form.royalties = []; // Prevent weird bug when livereload is enabled
    this.partnerRolesInCustomOrder.forEach((partnerRole) => {
      this.form.royalties.push({
        partnerRole: partnerRole["@id"],
        company: reductId,
        user: firstReductUserId,
        commissionPercentage: 0,
      });
    });

    this.form.contractProductDeviceUsages = []; // Prevent weird bug when livereload is enabled
    const products = await this.getAllProducts();
    products.forEach((product) => {
      this.form.contractProductDeviceUsages.push({
        product: product["@id"],
        nrOfDevices: 0,
      });
    });
    this.products = products;

    if (this.$route.query.renew) {
      const contract = await this.getContract(this.$route.query.renew);
      this.form.company = contract.company["@id"];
      this.form.totalRetailValue = contract.totalRetailValue;
      this.form.distanceUnits = contract.distanceUnits;
      this.form.pricePerDistanceUnit = contract.pricePerDistanceUnit;
      this.form.systemOfMeasurement = contract.systemOfMeasurement["@id"];
      this.form.currency = contract.currency["@id"];
      this.form.startDate = dayjs().format("YYYY-MM-DD");
      this.form.endDate = dayjs().add(1, "year").format("YYYY-MM-DD");
      this.form.fullInsurance = contract.fullInsurance;
      this.form.sellbackOption = contract.sellbackOption;
      this.form.sellbackPrice = contract.sellbackPrice;
      this.form.sellbackExpirationDate = contract.sellbackExpirationDate;
      this.form.nextContractEvaluationDate =
        contract.nextContractEvaluationDate;
      this.form.contractProductDeviceUsages =
        contract.contractProductDeviceUsages.map((cpdu) => ({
          ...cpdu,
          product: cpdu.product["@id"],
        }));
      const partners = await this.getPartners({ contract: contract["@id"] });
      partners.items.forEach((partner) => {
        const index = this.form.royalties.findIndex(
          (r) => r.partnerRole === partner.partnerRole
        );
        this.form.royalties[index] = {
          ...this.form.royalties[index],
          company: partner.company["@id"],
          user: partner.user["@id"],
          commissionPercentage: partner.commissionPercentage,
        };
      });
    }

    this.loading = false;
  },
  methods: {
    ...mapActions([
      "getAllCompanies",
      "getAllUsers",
      "getAllProducts",
      "getContract",
      "getPartners",
    ]),
    submit() {
      this.submitting = true;

      const data = {
        ...this.form,
        totalRetailValue: this.form.totalRetailValue.toString(),
        distanceUnits: this.form.distanceUnits.toString(),
        pricePerDistanceUnit: this.form.pricePerDistanceUnit.toString(),
        sellbackPrice: this.form.sellbackOption
          ? this.form.sellbackPrice.toString()
          : null,
        sellbackExpirationDate: this.form.sellbackOption
          ? this.form.sellbackExpirationDate
          : "2000-01-01",
      };

      this.$emit("submit", data);
    },
  },
};
</script>
