<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("contract", 2) }} &ndash;</span>
        {{ $t("create") }}
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'ContractIndex' }"></BackButton>

        <ContractForm @submit="submit"></ContractForm>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import ContractForm from "./partials/ContractForm";

export default {
  name: "ContractCreate",
  components: { BackButton, ContractForm },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions([
      "createContract",
      "createPartner",
      "getContract",
      "updateContract",
    ]),
    submit(payload) {
      this.loading = true;

      const { royalties, ...contract } = payload;

      this.createContract(contract)
        .then(async (contract) => {
          if (this.$route.query.renew) {
            const oldContract = await this.getContract(this.$route.query.renew);

            // Mark old contract as expired
            await this.updateContract({
              id: oldContract.id,
              contract: {
                endDate: dayjs(contract.startDate)
                  .subtract(1, "day")
                  .format("YYYY-MM-DD"),
              },
            });

            // Add devices to new contract
            await this.updateContract({
              id: contract.id,
              contract: {
                devices: oldContract.devices.map((d) => d["@id"]),
              },
            });
          }

          for (const r in royalties) {
            const royalty = royalties[r];
            await this.createPartner({
              commissionPercentage: royalty.commissionPercentage.toString(),
              company: royalty.company,
              contract: contract["@id"],
              partnerRole: royalty.partnerRole,
              user: royalty.user,
            });
          }

          this.$buefy.toast.open({
            message: this.$t("contract_created"),
            type: "is-primary",
          });
          this.$router.push({
            name: "ContractView",
            params: { id: contract.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
