<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $t("general") }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block">
      <CompanySelect
        v-model="form.company"
        :label="$tc('company')"
        :companies="companies"
        :horizontal="true"
      ></CompanySelect>

      <CustomInput
        v-model="form.totalRetailValue"
        :label="$t('total_retail_value')"
        :type="'number'"
        :icon="currency.code === 'EUR' ? 'currency-eur' : 'currency-usd'"
        @input="calculateEstimatedRoyalty"
      ></CustomInput>

      <CustomInput
        v-model="form.distanceUnits"
        :label="$t('distance_units')"
        :type="'number'"
        @input="calculateEstimatedRoyalty"
      ></CustomInput>

      <CustomInput
        v-model="form.pricePerDistanceUnit"
        :label="$t('price_per_distance_unit')"
        :step="0.01"
        :type="'number'"
        :icon="currency.code === 'EUR' ? 'currency-eur' : 'currency-usd'"
        @input="calculateEstimatedRoyalty"
      ></CustomInput>

      <CustomInput
        :value="estimate"
        :label="$t('estimated_royalty')"
        :type="'number'"
        :icon="currency.code === 'EUR' ? 'currency-eur' : 'currency-usd'"
        disabled
      ></CustomInput>

      <b-field
        horizontal
        :label="$tc('system_of_measurement', 1)"
        label-for="systemOfMeasurement"
      >
        <b-select
          id="systemOfMeasurement"
          v-model="form.systemOfMeasurement"
          required
        >
          <option
            v-for="s in systemOfMeasurements"
            :key="s.id"
            :value="s['@id']"
          >
            {{ s.name }}
          </option>
        </b-select>
      </b-field>

      <b-field horizontal :label="$tc('currency', 1)" label-for="currency">
        <b-select id="currency" v-model="form.currency" required>
          <option v-for="c in currencies" :key="c.id" :value="c['@id']">
            {{ c.name }}
          </option>
        </b-select>
      </b-field>

      <CustomDatePicker
        v-model="form.startDate"
        :label="$t('start_date')"
        :max="form.endDate"
      ></CustomDatePicker>

      <CustomDatePicker
        v-model="form.endDate"
        :label="$t('end_date')"
        :min="form.startDate"
      ></CustomDatePicker>

      <b-field horizontal label-for="fullInsurance" class="mt-4">
        <b-checkbox id="fullInsurance" v-model="form.fullInsurance">
          {{ $t("full_insurance") }}
        </b-checkbox>
      </b-field>

      <b-field horizontal label-for="sellbackOption">
        <b-checkbox id="sellbackOption" v-model="form.sellbackOption">
          {{ $t("sellback_option") }}
        </b-checkbox>
      </b-field>

      <CustomInput
        v-model="form.sellbackPrice"
        :disabled="!form.sellbackOption"
        :label="$t('sellback_price')"
        :type="'number'"
        :icon="currency.code === 'EUR' ? 'currency-eur' : 'currency-usd'"
      ></CustomInput>

      <CustomDatePicker
        v-model="form.sellbackExpirationDate"
        :disabled="!form.sellbackOption"
        :label="$t('sellback_expiration_date')"
        :min="form.startDate"
        :required="form.sellbackOption"
      ></CustomDatePicker>

      <CustomDatePicker
        v-model="form.nextContractEvaluationDate"
        :label="$t('next_evaluation_date')"
        :min="form.startDate"
        :max="form.endDate"
      ></CustomDatePicker>
    </div>
  </b-collapse>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CompanySelect from "../../../components/forms/CompanySelect";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: {
    CompanySelect,
    CustomDatePicker,
    CustomInput,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: null,
    },
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      estimate: 0,
    };
  },
  computed: {
    ...mapGetters(["findCurrencyByUri"]),
    ...mapState({
      currencies: (state) => state.global.currencies,
      systemOfMeasurements: (state) => state.global.systemOfMeasurements,
    }),
    currency() {
      return this.findCurrencyByUri(this.form.currency);
    },
  },
  watch: {
    "form.sellbackOption"(value) {
      if (value) {
        return;
      }
      this.form.sellbackPrice = null;
      this.form.sellbackExpirationDate = null;
    },
  },
  methods: {
    calculateEstimatedRoyalty() {
      this.estimate = this.form.distanceUnits * this.form.pricePerDistanceUnit;
    },
  },
};
</script>
