<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $t("royalty_distribution") }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block royalties-table">
      <table>
        <tbody>
          <ContractFormRoyaltiesRow
            v-for="royalty in form.royalties"
            :key="royalty.partnerRole"
            :form="form"
            :partner-role-id="royalty.partnerRole"
            :companies="companies"
            :users="users"
            :commission-percentage-total="commissionPercentageTotal"
          ></ContractFormRoyaltiesRow>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t("total") }}</td>
            <td colspan="2"></td>
            <td
              :class="{
                'has-text-success': commissionPercentageTotal === 100,
                'has-text-danger': commissionPercentageTotal !== 100,
              }"
            >
              <span class="pr-3">
                {{ commissionPercentageTotal | percentage }}
              </span>
            </td>
            <td>
              <small class="ml-2">
                {{ feeTotal | money(currency ? currency.code : null) }}
              </small>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </b-collapse>
</template>

<script>
import { mapGetters } from "vuex";
import ContractFormRoyaltiesRow from "./ContractFormRoyaltiesRow";

export default {
  components: { ContractFormRoyaltiesRow },
  props: {
    form: {
      type: Object,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    commissionPercentageTotal: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["findCurrencyByUri"]),
    currency() {
      return this.findCurrencyByUri(this.form.currency);
    },
    feeTotal() {
      return (
        (parseInt(this.form.distanceUnits || 0) *
          parseInt(this.form.pricePerDistanceUnit || 0) *
          this.commissionPercentageTotal) /
        100
      );
    },
  },
};
</script>
