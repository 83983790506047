<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $tc("product", 2) }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block products-table">
      <CustomInput
        v-for="p in form.contractProductDeviceUsages"
        :id="p.product"
        :key="p.product"
        v-model="p.nrOfDevices"
        :label="getProductName(p.product)"
        :placeholder="$t('amount')"
        :type="'number'"
      ></CustomInput>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    form: {
      type: Object,
      required: true,
      default: null,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions(["getAllProducts"]),
    getProductName(id) {
      return this.products.find((p) => p["@id"] === id).name;
    },
  },
};
</script>
